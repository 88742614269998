<template>
        <b-navbar id="footer" toggleable="true" type="dark" is-nav fluid="sm" fixed="bottom" style="background-color:black">
            <b-navbar-brand  text-variant="white">
                 © {{ new Date().getFullYear() }} {{ copyright }}
            </b-navbar-brand>
            <b-navbar-brand  href="/legal"
                             text-variant="white" >Legal notice</b-navbar-brand>
        </b-navbar>
</template>
<style>
#footer{
  height:40px;
}
    #footer .navbar-brand{
        white-space:normal;

    }
</style>
<script>
export default {
    data() {
        return {
            copyright: 'Parrot Drones SAS. All rights reserved.',
        }
    },
}
</script>

