<template>
      <div class="container main">
        <h1 class="mt-4 mb-3">Parrot Search Flight</h1>
        <b-row>
            <b-col cols="12">
                <h2>{{ page }}</h2>
                <p>{{ description }}</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="2">
            </b-col>
            <b-col cols="8">
            <span>
            Parrot Search Flight is a web based diagnostic tool that allows Parrot Partners to identify the flight characteristics to aid decision making for Technical Support requests
            <br>
            <br>
            Parrot Search Flight analyzes an ANAFI drone's flight data, based on the serial number (PI, PS or PF) and provides the Parrot Partner in a few moments if the drone issue is caused by a defect in the drone or the user, thus enabling the partner to make on the spot decision about warranty vs. non-warranty.
            <br>
            <br>
            It is essential that you ensure the user has shared their flight data via the FreeFlight app and a Parrot Partner should be using Parrot Search Flight with other applicable Parrot support documentation / guides.
            <br>
            <br>
            Once you have ensured the user has shared their flight data (note if they only just do this in FreeFlight 6, the flight data will take up to 24 hours to appear in Parrot Search Flight databases). You can then simply enter the serial number and begin. 
            </span>
            </b-col>
      </b-row>
      </div>
</template>

<script>
export default {
    name: "Home",
    metaInfo: {
        title: 'Home',
        titleTemplate: '%s | Search Flight'
    },
    data() {
        return {
            page: 'Welcome',
            description: ''
        }
    }
};
</script>

<style>
.light-color, .light-color:hover{
    color: #fff;
}
</style>
