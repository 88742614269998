<template>
  <div class="duration_item">
    <span style="margin:0;padding:0;">{{ getDurationString() }}</span>
  </div>
</template>

<script>


export default {
  name: "duration_item",
  props: {
    duration: {
      required: true,
      type: String
    }
  },
  methods: {
    getDurationString() {
       const duration  = (secs) => {
          secs = Math.round(secs)
          let hours = Math.floor(secs / (60 * 60));
          let divisor_for_minutes = secs % (60 * 60);
          let minutes = Math.floor(divisor_for_minutes / 60);
          let divisor_for_seconds = divisor_for_minutes % 60;
          let seconds = Math.ceil(divisor_for_seconds);
          // Format '1h 1min 1s' '1min 1s' '2mins 1s' '1s'
          return  (hours>0 ? hours+'h '  : '') +
                  (minutes>0 ? minutes + 'min' +(minutes>1?'s':'')  : '') +
                  (seconds +'s');
         }
      return duration(Number(this.duration));
    }
  }
};
</script>
