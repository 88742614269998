<template>
      <div class="container main">
        <h1 class="mt-4 mb-3">Legal Notice</h1>
        <b-row>
            <b-col cols="12">
                <h2>{{ page }}</h2>
                <p>{{ description }}</p>
                <p>The Parrot site is a publication by ParrotDrones SAS, a “Société par Actions Simplifiée”
                  with issued capital of €60,627,825.00, registered in the Trade and Companies Register of Paris
                  under the number 808&nbsp;408 074, whose head office is at 174 quai de Jemmapes, 75010 Paris (France)
                  and whose intra-Community VAT number is FR67808408074.</p>
                <p>Phone: + 33 (0)1 48 03 60 69</p>
                <p>Contacts:</p><p>
                Technical support:&nbsp;<a href="https://www.parrot.com/support">https://www.parrot.com/support</a><br>
                Online order related inquests:&nbsp;<a href="https://www.parrot.com/support">https://www.parrot.com/support</a><br>
                Jobs at Parrot: <a href="https://jobs.parrot.com/">https://jobs.parrot.com/</a><br>
                Parrot for developers:&nbsp;<a href="https://developer.parrot.com/">https://developer.parrot.com/</a><br>
                Investor relations: <a href="https://www.parrot.com/en/corporate">https://www.parrot.com/en/corporate</a><br>
                Press contact: <a href="https://www.parrot.com/en/press-contacts">https://www.parrot.com/en/press-contacts</a><br>
                Partnerships: <a href="https://www.parrot.com/en/partnerships">https://www.parrot.com/en/partnerships</a><br>
                Privacy policy: <a href="mailto:privacy@parrot.com">privacy@parrot.com</a><br>
                </p>
                <p>declaration receipt CNIL no. 1909876</p>
                <p>Director of the publication:&nbsp; Martin Liné</p>
                 <p>This website is designed by ParrotDrones SAS</p>
                 <p>Web hosting: Amazon Web Services&nbsp;EMEA SARL<br>
                     38 avenue John F. Kennedy,<br>L-1855 Luxembourg<br>
                     <a href="https://aws.amazon.com/">https://aws.amazon.com</a></p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "Legal",
    metaInfo: {
        title: 'Legal notice',
        titleTemplate: '%s | Search Flight'
    },
    data() {
        return {
            page: '',
            description: ''
        }
    }
};
</script>

