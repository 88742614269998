import { createRouter as _createRouter, createWebHistory } from 'vue-router'
import TokenService from '../services/storage.service'

import Home from './../views/Home.vue'
import Login from './../views/Login.vue'
import PageNotFound from './../views/PageNotFound.vue'
import Legal from './../views/Legal.vue'
import Password from './../views/Password.vue'
import Search from './../views/Search.vue'
import Lost from './../views/Lost.vue'
import Reset from './../views/Reset.vue'


const router = _createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        public: true,  // Allow access to guest user.
        onlyWhenLoggedOut: true
      }
    },
    {
      path: '/legal',
      name: 'Legal',
      component: Legal,
      meta: {
        public: true,  // Allow access to guest user.
      }
    },
    {
      path: '/password',
      name: 'Password',
      component: Password,
    },
    {
      path: '/search',
      name: 'Search',
      component: Search,
    },
    {
      path: '/lost',
      name: 'Lost',
      component: Lost,
      meta: {
        public: true,  // Allow access to guest user.
        onlyWhenLoggedOut: true
      }
    },
    {
      path: '/reset',
      name: 'Reset',
      component: Reset,
      meta: {
        public: true,  // Allow access to guest user.
        onlyWhenLoggedOut: false
      }
    },
    {
      // path: "*",
      path: "/:catchAll(.*)",
      name: "not-found",
      component: PageNotFound,
      meta: {
        requiresAuth: false
      }
    }
  ]
})




router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = !!TokenService.getToken();

  if (!isPublic && !loggedIn) {
    return next({
      path:'/login',
      query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
    });
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/')
  }

  next();
})

export function createRouter () { return router}
