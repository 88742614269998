const TOKEN_KEY = 'token'
const REFRESH_TOKEN_KEY = 'refresh_token'
const VERIFY_TOKEN_KEY = 'token'


/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instance.
**/
const TokenService = {
    getToken() {
        // eslint-disable-next-line no-console
        return localStorage.getItem(TOKEN_KEY)
    },

    saveToken(accessToken) {
        localStorage.setItem(TOKEN_KEY, accessToken)
    },
    removeToken() {
        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(VERIFY_TOKEN_KEY)
        localStorage.removeItem(REFRESH_TOKEN_KEY)
    },
    getVerifyToken() {
        this.app = localStorage.getItem(TOKEN_KEY);
        if (typeof(this.app) !== 'undefined' && this.app !== null && this.app !== ''){
            return this.app;
        }
        return '';
    },
    saveVerifyToken(accessToken) {
        localStorage.setItem(VERIFY_TOKEN_KEY, accessToken)
    },
    removeVerifyToken() {
        localStorage.removeItem(VERIFY_TOKEN_KEY)
    },
    getRefreshToken() {
        return localStorage.getItem(REFRESH_TOKEN_KEY)
    },
    saveRefreshToken(refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    },
    removeRefreshToken() {
        localStorage.removeItem(REFRESH_TOKEN_KEY)
    }

}

export default TokenService
