<template>
  <div class="date_item">
    <span>
      {{getHourString()}}
      <br>
      {{getDateString()}}
    </span>
  </div>
</template>

<script>
//import moment from "moment";
export const zero = (n) => {return  ('0' + n.toString()).slice(-2)};

export default {
  name: "date_item",
  props: {
    date: {
      required: true
    }
  },
  methods: {
    getDateString() {

      const regex = /[0-9]{4}(-[0-9]{1,2}){2}/gm;
      let result =  this.date.match(regex) || [""];
      let date  =  new Date(result)

      const days = ['Sun', 'Mon', 'Tue', 'Wed','Thu', 'Fri', 'Sat'];
      const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November',  'December'];

      const nth = (n) => {
        let ord = ["st", "nd", "rd"]
        let exceptions = [11, 12, 13]
        return ord[(n % 10) - 1] === undefined || exceptions.includes(n % 100) ? "th" : ord[(n % 10) - 1]
      };

     return(date.getFullYear() +' '
           + days[date.getDay()] +' '
          + months[date.getMonth()] +' '
          + date.getDate()   + ''
          +   nth(date.getDate())
      );
    },
    getHourString() {
      const regex = /[A-Z]([0-9]{1,2}:)[0-9]{1,2}/gm;
      return  this.date.match(regex)[0].slice(-5) || [""];
    }
  }
};
</script>