<template>
  <div class="container main">
    <b-alert show variant="success" v-if="resetPasswordSuccess">
      If your email address has already been registered in our database,
      an email will be sent with further instructions.</b-alert>
    <b-card class="mt-3" header="Lost / change password or connecting for the first time." v-if="!resetPasswordSuccess">
      <b-form @submit="onSubmit" validated="validated">
        <b-form-group
                label="Email address:" label-for="email"  description="Please enter a valid email address.">
          <b-form-input
                  id="email"
                  v-model="form.email"
                  type="email"
                  required
                  placeholder="Enter email" trim
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary" class="mr-2 active">Submit</b-button>
      </b-form>
    </b-card>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: "Reset",
    metaInfo: {
      title: 'Reset',
      titleTemplate: '%s | Reset Password',
      description: 'Reset password.'
    },
    data() {
      return {
        form: {
          email: "",
        }
      }
    },
    computed: {
      ...mapGetters('auth', [
        'resetPasswordSuccess',
      ])
    },
    methods: {
      ...mapActions('auth', ['resetpassword']),
      onSubmit(evt) {
        evt.preventDefault()
        // Perform a simple validation that email and password have been typed in
        if (this.form.email !== '') {
          this.resetpassword({email: this.form.email})
        }
      }
    }
  }
</script>
