<template>
  <div class="container main">
    <b-alert show variant="danger" v-if="!checkTokenSuccess" >
      bad link or bad request</b-alert>
  </div>
</template>
<script>
  import {mapActions, mapGetters} from "vuex";

  export default {
    name: "Lost",
    metaInfo: {
      title: 'Lost',
      titleTemplate: '%s | Search Flight',
      description: 'Change Password page.'
    },
    data () {
      return {
        page: 'Lost',
        loading: false,
         }
    },
    created () {
        this.fetchData();
    },
    watch: {
      // call again the method if the route changes
      '$route': 'fetchData'
    },
    computed: {
      ...mapGetters('auth', ['checkTokenSuccess'])
    },
    methods: {
      ...mapActions('auth', ['checkTokenlost','logout']),
      fetchData () {
        if(this.$route.query.token !=='') {
           this.checkTokenlost({token:this.$route.query.token, redirect: true});
        } else {
          this.logout();
        }
      },
    }
  }
</script>
