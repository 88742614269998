<template>
  <div class="eof_item">
    <span style="margin:0;padding:0;">
      {{flight_obj.eof.toUpperCase() }}
    </span>
  </div>
</template>

<script>
export default {
  name: "eof_item",
  props: {
    flight_obj: {
      required: true,
      type: Object
    }
  }
};
</script>
