<template>

        <b-navbar toggleable="lg" type="dark" style="background-color:black">
            <div class="container">
                <b-navbar-brand href="/" tile="Home">
                 <img src="/logo.png" alt="Parrot" width="387" height="100" />
                </b-navbar-brand>
                 <b-nav-form @submit="onSubmit" v-if="loggedIn" role="search">
                     <b-input-group size="lg" class="mr-lg-2">
                     <b-input-group-prepend is-text>
                         <b-icon-search icon="search"></b-icon-search>
                     </b-input-group-prepend>
                    <b-form-input placeholder="Serial number"
                                  id="serial"
                                  v-model="form.search"
                                  required="required" name="serial"
                    ></b-form-input>
                         </b-input-group>
                    <b-button size="lg" type="submit">Search</b-button>
                </b-nav-form>
                <b-navbar-brand v-if="loggedIn" href="/reset">
                    <b-icon-person-fill icon="person-fill"></b-icon-person-fill>Settings
                </b-navbar-brand>
                <b-navbar-brand v-if="loggedIn"  @click="logout" href="#">
                    <b-icon-power icon="power"></b-icon-power> Logout
                </b-navbar-brand>
                <b-navbar-brand v-if="!loggedIn && ['reset'].indexOf($route.name) > -1" href="/reset">
                    <b-icon-person-fill icon="person-fill"></b-icon-person-fill>
                    Lost Password.
                </b-navbar-brand>

            </div>
        </b-navbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators';
import EventBus from '@/eventBus'


export default {
  setup () {
     return { v$: useVuelidate() }
  },
    data() {
        return {
            form: {
                search:  (new URL(document.location)).searchParams.get("q")
            },
            page: 'result Search Flight',
        }
    },
    validations: {
        search: {
            required,
            minLength: minLength(4)
        },
    },
    computed: {
        ...mapGetters('auth', ['loggedIn'])
    },
    methods: {
        ...mapActions('auth', ['logout']),
        onSubmit(evt) {
            evt.preventDefault()
            if(this.$router.currentRoute.path === "/search"){
                EventBus.$emit('DATA_SEARCH', this.form.search)
            } else{
               window.location.href = '/search?q=' + this.form.search;
           }
        }
    }
}
</script>

