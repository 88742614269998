import {BootstrapVue, BIconSearch, BIconPower, BIconPersonFill} from 'bootstrap-vue'
import { createMetaManager  } from 'vue-meta'

import ApiService from './services/api.service'
import TokenService from './services/storage.service'

import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


import { createRouter } from './router'
import { createApp } from 'vue'
import { createStore } from './store'
const router = createRouter()
const store = createStore(router)
const app = createApp(App)
const metaManager = createMetaManager()
app.use(store)
app.use(router)
app.use(metaManager)

//Global filters

app.config.productionTip = false
app.use(BootstrapVue);
app.component('b-icon-search', BIconSearch);
app.component('b-icon-power', BIconPower);
app.component('b-icon-person-fill', BIconPersonFill);

// Set the base URL of the API
ApiService.init(process.env.VUE_APP_ROOT_API);
ApiService.setHeaderAPI();
// If token exists set header
if (TokenService.getToken()) {
  ApiService.setHeader()
}

app.mount('#app')

