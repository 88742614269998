<template>
    <div class="container main">
        <h1 class="mt-4 mb-3">Parrot Search Flight</h1>
        <b-row v-show="isModalVisible" style="text-align:center">
            <b-col>
                <b-row>
                    <b-col>
                    <p class="text-right">
                    <button
                        type="button"
                        class="btn-close"
                        @click="closeModal"
                        style="width:100px"
                    >
                    Close
                    </button>
                    </p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <img src="/EOF_warranty.png" alt="EOF Warranty">
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row v-if="!isModalVisible">
            <b-col cols="8">
                <h2>{{ page }} </h2>
                <p v-if="post !== null">{{ description }} - {{ post.length }} flights found</p>
            </b-col>
            <b-col cols="4">
                <div style="text-align:right">
                    <input type="button" @click="showModal" value="EOF Warranty process"/>
                </div>
            </b-col>
        </b-row>

        <div class="post">
            <div v-if="loading" class="loading">
              <span>L</span>
              <span>o</span>
              <span>a</span>
              <span>d</span>
              <span>i</span>
              <span>n</span>
              <span>g</span><span>.</span><span>.</span><span>.</span>
            </div>
            <div v-if="error==='fetch'" class="error">
                <b-row><p>
                <b-alert show variant="error">
                    No result</b-alert>
                </p></b-row>
            </div>
            <div v-if="error==='serial'" class="error">
                <b-row><p>
                <b-alert show variant="error">
                    Wrong serial, expected format is a 18 character strings like PLXXXXXXLLXLXXXXXX, L being a letter in caps, and X being a digit</b-alert>
                </p></b-row>
            </div>
            <div v-if="!loading && !error && !isModalVisible && post !== null">
                <table  class="flight-table" style="width:100%">
                  <caption style="display: none"> Data flight : results</caption>
                 <thead>
                    <tr>
                    <!-- 'flight_date','duration',  'product', 'firmware', 'controller', 'controller_sw',  'eof', 'eof_details','serial' -->
                    <th scope="col" style="width:250px">Date</th>
                    <th scope="col" style="width:150px">Duration</th>
                    <th scope="col" style="width:220px">Drone</th>
                    <th scope="col" style="width:220px">Controller</th>
                    <th scope="col" style="width:auto">EOF</th>
                    </tr>
                </thead>
                <tbody>
                  <tr  v-for="flight in post" v-bind:key="flight.boot_uuid"  >
                    <td style="text-align:right">
                        <date_item v-bind:date="flight.flight_date"></date_item>
                    </td>
                    <td>
                        <duration_item :duration="flight.duration"></duration_item>
                    </td>
                    <td>
                        <drone_item :flight_obj="flight"></drone_item>
                    </td>
                    <td>
                        <controller_item :flight_obj="flight"></controller_item>
                    </td>
                    <td>
                        <eof_item :flight_obj="flight"></eof_item>
                    </td>
                  </tr>
                </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import ApiService from "../services/api.service";
import EventBus from '../eventBus'

import date_item from "./flightsItems/Date";
import duration_item from "./flightsItems/Duration";
import drone_item from "./flightsItems/Drone";
import controller_item from "./flightsItems/Controller";
import eof_item from "./flightsItems/EndOfFlight";

export default {
    name: "Search",
    metaInfo: {
        title: 'Search',
        titleTemplate: '%s | Search Flight'
    },
    components: {
        date_item,
        duration_item,
        drone_item,
        controller_item,
        eof_item
    },
    data () {
            return {
            page: '',
            description: this.$route.query.serial,
            loading: false,
            post: [],
            error: null,
            columns: ['flight_date','duration',  'product', 'firmware', 'controller', 'controller_sw',  'eof', 'eof_details','serial'],
            isModalVisible: false,
        }
    },
    created () {
        // fetch the data when the view is created and the data is
        // already being observed
        this.fetchData(this.$route.query.q)
    },
    watch: {
        // call again the method if the route changes
        '$route': 'fetchData'
    },
    methods: {
        ...mapActions('auth', ['logout']),
        showModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
        fetchData (data) {
            this.error = this.post = null
            this.loading = true;
            this.description = data;
            let serial = data.replace(" ","").replace("-","")
             window.history.replaceState(null, '', '?q='+serial);
            let regex = "^P[IFS]\\d{6}[A-Z][A-Z0-9]\\d[A-L]\\d{6}$"
            let match = serial.match(regex) 
            if (match) {
                ApiService.get(process.env.VUE_APP_URL_SEARCH +'?serial=' + serial)
                    .then((res) => {
                        this.status = res.status;
                        if(this.status === 200){
                            this.post = res.data;
                        } else {
                            this.error = 'fetch';
                        }
                      this.loading = false;
                    })
                    .catch((error) => {
                        this.error = 'fetch';
                        if(error.response.status !== 404){
                            this.logout()
                        }
                     this.loading = false;
                })
            } else {
                this.error = 'serial';
                this.loading = false;
            }

        }
    },
    mounted () {
        EventBus.$on('DATA_SEARCH', (payload) => {
            this.fetchData(payload)
        })
    }
}


</script>


<style lang="css">

.flight-table {
  width: 100%;
  margin-bottom: 0.5em;
  border: solid #ccc 1px;
  border-radius: 6px;
  -moz-border-radius: 6px;
}

.flight-table th {
  background-color: #eee;
  border-top: none;
}

.flight-table td {
    border-top: solid #ccc 1px;
    border-bottom: solid #ccc 1px;
}

.flight-table tr td {
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.3em;
  vertical-align: bottom;
  
}

.flight-table tr th {
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.3em;
}
.loading span {
    animation: loading01 1s infinite alternate;
    font-size: 20px;
}
.loading span:nth-child(1) { animation-delay: 0s; }
.loading span:nth-child(2) { animation-delay: 0.1s; }
.loading span:nth-child(3) { animation-delay: 0.2s; }
.loading span:nth-child(4) { animation-delay: 0.3s; }
.loading span:nth-child(5) { animation-delay: 0.4s; }
.loading span:nth-child(6) { animation-delay: 0.5s; }
.loading span:nth-child(7) { animation-delay: 0.6s; }
.loading span:nth-child(8) { animation-delay: 0.7s; }
.loading span:nth-child(9) { animation-delay: 0.8s; }
.loading span:nth-child(10) { animation-delay: 0.9s; }
.loading span:nth-child(11) { animation-delay: 1s; }

@keyframes loading01 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

</style>
