<template>
  <div class="container main">
    <b-alert show variant="danger" v-if="authenticationErrorCode">
      Bad email or password</b-alert>
    <b-alert show variant="success" v-if="authenticationSuccess">
      authentication Success</b-alert>
    <b-alert show variant="success" v-if="logoutSuccess">
      authentication Success</b-alert>
    <b-card class="mt-3" header="Sign in">
      <b-form @submit="onSubmit" validated="validated" >
        <b-form-group  label="Email address:" label-for="email">
          <b-form-input
            id="email"
            v-model="form.email"
            type="email"
            required
            placeholder="Enter email"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Password:" label-for="password">
          <b-form-input
            id="password"
            v-model="form.password"
            type="password"
            required
            placeholder="Enter password"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary" class="mr-2 active">Login</b-button>
        <b-button variant="primary" disabled v-if="authenticating">
          <b-spinner small type="grow"></b-spinner>
          verifying your details...
        </b-button>
      </b-form>
    </b-card>
    <b-link href="/reset" style="color:#0052B8">Lost password ?</b-link>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "Login",
    metaInfo: {
        title: 'Login',
        titleTemplate: '%s | Search Flight',
        description: 'Change Password page.'
    },
    data() {
      return {
        form: {
            email: "",
            password: "",
        }
      }
    },
  errorCaptured(err, vm, info) {
    this.err = err;
    this.vm = vm;
    this.info = info;
    return !this.stopPropagation;
  },
    computed: {
        ...mapGetters('auth', [
            'authenticating',
            'authenticationError',
            'authenticationErrorCode',
            'authenticationSuccess',
            'loggedIn',
             'logoutSuccess',
        ])
    },
    methods: {
        ...mapActions('auth', ['login']),
        onSubmit(evt) {
            evt.preventDefault()
            // Perform a simple validation that email and password have been typed in
            if (this.form.email !== '' && this.form.password !== '') {
                 this.login({email: this.form.email, password: this.form.password})
            }
        }
    }
}
</script>
