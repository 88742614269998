<template>
  <div class="container main">
    <b-alert show variant="danger" v-if="authenticationErrorCode">
      Bad email or password</b-alert>
    <b-alert show variant="success" v-if="passwordSuccess">
      Password changed <b-link href="/">return to home</b-link></b-alert>
    <b-card class="mt-3" header="Change password. All fields are required."  v-if="!passwordSuccess">
      <b-form @submit="onSubmit" validated="validated">
        <b-form-group
                label="Email address:" label-for="email"  description="Please enter a valid email address.">
          <b-form-input
                  id="email"
                  v-model="form.email"
                  type="email"
                  required
                  placeholder="Enter email" trim
          ></b-form-input>
        </b-form-group>
        <b-form-group label="current Password:"
                      label-for="currentpassword" v-if="!vtoken">
          <b-form-input
                  id="currentpassword"
                  v-model="form.currentpassword"
                  type="password"
                  required
                  placeholder="Enter current password"

          ></b-form-input>
        </b-form-group>
        <b-form-group label="New Password:" label-for="password1">
          <b-form-input
                  id="password1"
                  v-model="form.password1"
                  type="password"
                  required
                  placeholder="Enter new password"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="retype new Password:" label-for="password2">
          <b-form-input
                  id="password2"
                  v-model="form.password2"
                  type="password"
                  required
                  placeholder="retype new password"
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary" class="mr-2 active">Submit</b-button>
        <b-button variant="primary" disabled v-if="authenticating">
          <b-spinner small type="grow"></b-spinner>
          verifying your details...
        </b-button>
      </b-form>
    </b-card>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from "vuex";
  import TokenService from "../services/storage.service";

  export default {
    name: "Password",
    metaInfo: {
      title: 'Password',
      titleTemplate: '%s | Search Flight',
      description: 'Change Password page.'
    },
    data() {
      return {
        form: {
          email: "",
          currentpassword: "",
          password1: "",
          password2: "",
        },
        vtoken: false
      }
    },
    computed: {
      ...mapGetters('auth', [
        'authenticating',
        'authenticationError',
        'authenticationErrorCode',
        'authenticationSuccess',
        'passwordSuccess',
      ])
    },
    mounted() {
      this.vt();
    },
    methods: {
      ...mapActions('auth', ['password']),
      vt(){
        this.app = TokenService.getVerifyToken();
        this.vtoken= (typeof(this.app) !== 'undefined' && this.app !== null && this.app !== '')
      },
      onSubmit(evt) {
        evt.preventDefault();
        // Perform a simple validation that email and password have been typed in
        // Vérification basique
        this.pass = this.form.email !== '' &&
                this.form.password1 !== '' &&
                this.form.password2 !== '' &&
                this.form.password1 === this.form.password2;

        // Vérification : si vtoken n'est pas présent alors, on vérifie le password
        if(!this.vtoken) {
          this.pass = this.pass && (this.form.currentpassword !=='');
        }
        if (this.pass) {
          this.password({
            username: this.form.email,
            token: this.form.currentpassword,
            newpassword: this.form.password1
          })
        }
      }
    }
  }
</script>
