<template>
  <div id="app">
    <app-header/>
    <router-view></router-view>
    <app-footer/>
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'

export default {
  name: 'app',
  data(){
    return {
      appName: process.env.VUE_APP_NAME
    }
  },
  components: {
    'app-header' : Header,
    'app-footer' : Footer
  }
}
</script>
