import { UserService, AuthenticationError } from '@/services/user.service'
import TokenService from '@/services/storage.service'


const state =  {
    authenticating: false,
    accessToken: TokenService.getToken(),
    authenticationSuccess: false,
    authenticationErrorCode: 0,
    authenticationError: '',
    passwordchangedsuccess: false,
    askresetpassword: false,
    checkToken: false,
    verifytokenExist: false,
    logoutSuccess: false,
}

const getters = {
    loggedIn: (state) => {
        return !!state.accessToken
    },

    authenticationErrorCode: (state) => {
        return state.authenticationErrorCode
    },

    authenticationError(state){
        return state.authenticationError
    },

    authenticationSuccess(state){
        return state.authenticationSuccess
    },

    authenticating: (state) => {
        return state.authenticating
    },
    passwordSuccess: (state) => {
    return state.passwordchangedsuccess
    },
    resetPasswordSuccess(state) {
        return state.askresetpassword;
     },
    checkTokenSuccess(state) {
        return state.checkToken;
    },
    logoutSuccess(state){
        return state.logoutSuccess
    },
}

const actions = {
    async login({ commit }, {email, password}) {
        commit('loginRequest');
        try {
            const token = await UserService.login(email, password);
            commit('loginSuccess', token)
            window.location.href=(new URL(document.location)).searchParams.get("redirect") ?? "/"
            return true
        } catch (e) {
            if (e instanceof AuthenticationError) {
                commit('loginError', {errorCode: e.errorCode, errorMessage: e.message})
            }
            return false
        }
    },

    logout({ commit }) {
        UserService.logout();
        commit('logoutSuccess', true);
        window.location.href = '/login';
    },

    async password({ commit }, {username, newpassword, token}) {
        commit('passwordRequest');
        try {
            const returntoken = await UserService.passwordchange(username, newpassword, token);
            commit('passwordSuccess', returntoken)
            commit('loginSuccess', returntoken)

            // Redirect the user to the page he first tried to visit or to the home view
            //router.push(router.history.current.query.redirect || '/');
            return true
        } catch (e) {
            if (e instanceof AuthenticationError) {
                commit('passwordError', {errorCode: e.errorCode, errorMessage: e.message})
            }
            return false
        }
    },
    async resetpassword({ commit }, {email}) {
        try {
            const result = await UserService.reset(email);
            // Redirect the user to the page he first tried to visit or to the home view
            //router.push(router.history.current.query.redirect || '/');
            commit('resetPasswordSuccess', result)
            return true
        } catch (e) {
            if (e instanceof AuthenticationError) {
                commit('resetPasswordSuccess', false)
            }
            return false
        }
    },
    async checkTokenlost({ commit }, {token, redirect = false}) {
        try {
            await UserService.checkreset(token);

            commit('checkTokenSuccess', true)
            // Redirect the user to the page he first tried to visit or to the home view
            if(redirect){
                window.location.href = '/password'
            }
            return true;
        } catch (e) {
            if (e instanceof AuthenticationError) {
                commit('CheckTokenError', {errorCode: e.errorCode, errorMessage: e.message})
            }
            return false
        }
    },
}

const mutations = {
    loginRequest(state) {
        state.authenticating = true;
        state.authenticationError = ''
        state.authenticationErrorCode = 0
    },

    loginSuccess(state, accessToken) {
        state.accessToken = accessToken
        state.authenticationSuccess = true;
        state.authenticating = false;
    },

    loginError(state, {errorCode, errorMessage}) {
        state.authenticating = false
        state.authenticationErrorCode = errorCode
        state.authenticationError = errorMessage
    },
    passwordRequest(state) {
        state.authenticating = true;
        state.authenticationError = ''
        state.authenticationErrorCode = 0
    },

    passwordSuccess(state, accessToken) {
        state.accessToken = accessToken
        state.authenticationSuccess = true;
        state.authenticating = false;
        state.passwordchangedsuccess = true
    },
    passwordError(state, {errorCode, errorMessage}) {
        state.authenticating = false
        state.authenticationErrorCode = errorCode
        state.authenticationError = errorMessage
    },
    resetPasswordSuccess(state) {
        state.askresetpassword = true;
    },
    checkTokenSuccess(state) {
        state.checkToken = true ;
    },
    verifytokenExistResult(state) {
        state.verifytokenExist = true ;
    },
    CheckTokenError(state, {errorCode, errorMessage}){
        state.checkToken = false;
        state.authenticationErrorCode = errorCode
        state.authenticationError = errorMessage

    },
    logoutSuccess(state) {
        state.logoutSuccess = true;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
