/** VUEX store definition **/
import { createStore as _createStore } from 'vuex'

import auth from './modules/auth'


export function createStore (router) {
  return _createStore({
    state: {
      get route() {
        return router.currentRoute.value
      },
    },
  modules: {
    auth
  },
  strict: false
})
}