import ApiService from './api.service'
import TokenService from './storage.service'


class AuthenticationError extends Error {
    constructor(errorCode, message) {
        super(message)
        this.name = this.constructor.name
        this.message = message
        this.errorCode = errorCode
    }
}

const UserService = {
    /**
     * Login the user and store the access token to TokenService.
     *
     * @returns access_token
     * @throws AuthenticationError
    **/
    login: async function (email, password) {
        const requestData = {
            method: 'post',
            url:  process.env.VUE_APP_URL_LOGIN,
            data: {
                grant_type: 'password',
                username: email,
                password: password
            },
            auth: {
                username: process.env.VUE_APP_CLIENT_ID,
                password: process.env.VUE_APP_CLIENT_SECRET
            },
             headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.VUE_APP_CLIENT_SECRET
                }
        };

        try {
            const DecodeJWT= (token) => {
                try {
                    return JSON.parse(atob(token.split('.')[1]));
                } catch (e) {
                    return null;
                }
            }
            console.log(requestData.data)

            const response = await ApiService.customRequest(requestData);
            let payload = DecodeJWT(response.data.token);
            if(requestData.data.username !== payload.username) {
                throw new AuthenticationError("403", "problem")
            }
            TokenService.saveToken(response.data.token)
            ApiService.setHeader();

            // NOTE: We haven't covered this yet in our ApiService
            // but don't worry about this just yet - I'll come back to it later.
            // ApiService.mount401Interceptor();
            return response.data.token
        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data.detail)
        }
    },

    passwordchange: async function(username, newpassword) {
        const requestData = {
            method: 'post',
            url: process.env.VUE_APP_URL_LOST_PWD_CHANGE,
            data: {
                username,
                token: TokenService.getToken(),
                newpassword,
            },
            auth: {
                username: process.env.VUE_APP_CLIENT_ID,
                password: process.env.VUE_APP_CLIENT_SECRET
            },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': process.env.VUE_APP_CLIENT_SECRET
            }
        }
        try {
            const response = await ApiService.customRequest(requestData);
            TokenService.saveToken(response.data.token)
            ApiService.setHeader()
            // NOTE: We haven't covered this yet in our ApiService
            //       but don't worry about this just yet - I'll come back to it later
            // ApiService.mount401Interceptor();
            return response.data.token
        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data.detail)

        }
    },

    reset: async function(email) {
        const requestData = {
            method: 'post',
            url: process.env.VUE_APP_URL_LOST_PWD_RESET,
            data: {
                email,
            },
             headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': process.env.VUE_APP_CLIENT_SECRET
            }
        }
        try {
            await ApiService.customRequest(requestData);
            return true;
        } catch (error) {
            return false;
        }
    },

    checkreset: async function(token) {
        const requestData = {
            method: 'get',
            url: process.env.VUE_APP_URL_LOST_PWD_CHECK + '?token=' + token,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': process.env.VUE_APP_CLIENT_SECRET
            }
        }
        try{
                const response = await ApiService.customRequest(requestData)
                TokenService.saveToken(token);
                TokenService.saveVerifyToken(token);
                 // Update the header in ApiService.
                 ApiService.setHeader()
                return response.data.email;
    } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data.detail)
        }
    },
    /**
     * Logout the current user by removing the token from storage.
     *
     * Will also remove `Authorization Bearer <token>` header from future requests.
    **/
    logout: async function () {
        const requestData = {
            method: 'post',
            url:  process.env.VUE_APP_URL_LOGOUT,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': process.env.VUE_APP_CLIENT_SECRET
            }
    };
        try {
            TokenService.removeToken()
            TokenService.removeRefreshToken()
            ApiService.removeHeader()
            const response = await ApiService.customRequest(requestData);
            ApiService.setHeader();

            // NOTE: We haven't covered this yet in our ApiService
            // but don't worry about this just yet - I'll come back to it later.
            // ApiService.mount401Interceptor();
            return response?.data?.token
        } catch (error) {

            let status = error?.response?.status ?? 500
            let detail = error?.response?.data?.detail ?? "error"
            throw new AuthenticationError(status, detail)
        }
    }
}

export default UserService

export { UserService, AuthenticationError }
